<template>
  <div :style="{ minHeight: height }" ref="chartdiv"></div>
</template>

<script>
import am4lang_sv_SE from "@amcharts/amcharts4/lang/sv_SE"
import am4lang_en from "@amcharts/amcharts4/lang/en"
import {
  useTheme,
  create,
  color,
  Tooltip,
  percent,
  addLicense,
} from "@amcharts/amcharts4/core"
import {
  XYChart,
  DateAxis,
  ValueAxis,
  ColumnSeries,
  XYCursor,
  XYChartScrollbar,
} from "@amcharts/amcharts4/charts"
import am4themes_animated from "@amcharts/amcharts4/themes/animated"

if (process.env.VUE_APP_AMCHARTS4_LICENSE)
  addLicense(process.env.VUE_APP_AMCHARTS4_LICENSE)

useTheme(am4themes_animated)

export default {
  props: {
    data: Array,
    unit: String,
    goal: Number,
    height: { type: String, default: "500px" },
    zoomDates: { type: Boolean, default: true },
  },
  data() {
    return {
      onEnd: false,
    }
  },
  watch: {
    data(val) {
      if (this.chart) {
        this.chart.data = val
      }
    },
    onEnd(val) {
      if (val) {
        this.$emit("scrollEnd")
      }
    },
  },

  mounted() {
    let chart = create(this.$refs.chartdiv, XYChart)

    // Set language according to store
    chart.language.locale = { se: am4lang_sv_SE, en: am4lang_en }[
      this.$store.getters.language
    ]

    chart.data = this.data.slice().reverse()

    chart.zoomOutButton.disabled = true

    let dateAxis = chart.xAxes.push(new DateAxis())
    dateAxis.renderer.grid.template.location = 0
    dateAxis.renderer.grid.template.strokeOpacity = 0
    dateAxis.renderer.minGridDistance = 15
    dateAxis.renderer.labels.template.verticalCenter = "middle"
    dateAxis.renderer.labels.template.horizontalCenter = "left"
    dateAxis.tooltipDateFormat = "yyyy-MM-dd"
    dateAxis.renderer.labels.template.location = -0.001 // only works with this number or less, not zero
    dateAxis.renderer.labels.template.rotation = 45
    dateAxis.renderer.labels.template.textAlign = "middle"
    dateAxis.dateFormats.setKey("day", "dd/MM")
    dateAxis.periodChangeDateFormats.setKey("day", "dd/MM")
    dateAxis.keepSelection = true

    let valueAxis = chart.yAxes.push(new ValueAxis())
    valueAxis.title.text = this.unit
    valueAxis.renderer.inside = true
    valueAxis.renderer.labels.template.fillOpacity = 0.3
    valueAxis.renderer.grid.template.strokeOpacity = 0
    valueAxis.min = 0
    valueAxis.cursorTooltipEnabled = false
    valueAxis.includeRangesInMinMax = true
    valueAxis.renderer.gridContainer.zIndex = 1
    valueAxis.keepSelection = true

    if (this.goal) {
      // Horizontal line for point goal
      let axisRange = valueAxis.axisRanges.create()
      axisRange.value = this.goal
      axisRange.grid.strokeOpacity = 0.5
      axisRange.label.text = this.$t("goal")
      axisRange.label.align = "right"
      axisRange.label.verticalCenter = "bottom"
      axisRange.label.fillOpacity = 0.8
      //* Alternative styling:
      // axisRange.grid.stroke = color(this.$cssVar("--success"))
      // axisRange.grid.strokeWidth = 3
      // Hover tooltip to show goal
      axisRange.grid.tooltip = new Tooltip()
      axisRange.grid.tooltipText = `${this.$t("goal")}: ${
        this.goal
      } ${this.unit.toLocaleLowerCase()}`
      axisRange.grid.interactionsEnabled = true
      axisRange.grid.isMeasured = true
    }

    // Create activity points series
    let activitySeries = chart.series.push(new ColumnSeries())
    activitySeries.dataFields.valueY = "points_activity"
    activitySeries.dataFields.dateX = "date"
    activitySeries.name = this.$t("activitypoints")
    activitySeries.stacked = true
    // activitySeries.tooltipText =
    // "{valueY.value} " + this.$t("activitypoints").toLocaleLowerCase()
    activitySeries.fill = color(this.$cssVar("--primary"))
    activitySeries.tooltip.pointerOrientation = "vertical"
    // Needed for pre-zoom
    activitySeries.showOnInit = false

    // Create bonus series
    let bonusSeries = chart.series.push(new ColumnSeries())
    bonusSeries.dataFields.valueY = "points_bonus"
    bonusSeries.dataFields.dateX = "date"
    bonusSeries.name = this.$t("missionpoints")
    bonusSeries.stacked = true
    // bonusSeries.tooltipText =
    // "{valueY.value} " + this.$t("missionpoints").toLocaleLowerCase()
    bonusSeries.fill = color(this.$cssVar("--success"))
    bonusSeries.tooltip.pointerOrientation = "vertical"
    // Needed for pre-zoom
    bonusSeries.showOnInit = false

    // Create quiz series
    let quizSeries = chart.series.push(new ColumnSeries())
    quizSeries.dataFields.valueY = "points_quiz"
    quizSeries.dataFields.dateX = "date"
    quizSeries.name = this.$t("quizpoints")
    quizSeries.stacked = true
    // quizSeries.tooltipText =
    // "{valueY.value} " + this.$t("quizpoints").toLocaleLowerCase()
    quizSeries.fill = color(this.$cssVar("--accent"))
    quizSeries.tooltip.pointerOrientation = "vertical"
    // Needed for pre-zoom
    quizSeries.showOnInit = false

    function topRadius(radius, item) {
      let dataItem = item.dataItem
      // Find the last series in this stack
      let visibleSeries = []
      chart.series.each(series => {
        if (!series.isHidden && !series.isHiding) {
          visibleSeries.push(series)
        }
      })
      if (visibleSeries.length === 1) return 15

      if (visibleSeries.includes(dataItem.component)) {
        switch (dataItem.component.dataFields.valueY) {
          case "points_quiz":
            return 15
          case "points_bonus":
            if (
              !dataItem.dataContext.points_quiz ||
              !visibleSeries.find(e => e.dataFields.valueY == "points_quiz")
            ) {
              return 15
            }
            break
          case "points_activity":
            if (
              (!dataItem.dataContext.points_quiz ||
                !visibleSeries.find(
                  e => e.dataFields.valueY == "points_quiz"
                )) &&
              (!dataItem.dataContext.points_bonus ||
                !visibleSeries.find(e => e.dataFields.valueY == "points_bonus"))
            ) {
              return 15
            }
            break
        }
      }
      return radius
    }
    function bottomRadius(radius, item) {
      let dataItem = item.dataItem
      // Find the last series in this stack
      let visibleSeries = []
      chart.series.each(series => {
        if (!series.isHidden && !series.isHiding) {
          visibleSeries.push(series)
        }
      })
      if (visibleSeries.length === 1) return 15

      if (visibleSeries.includes(dataItem.component)) {
        switch (dataItem.component.dataFields.valueY) {
          case "points_quiz":
            if (
              (!dataItem.dataContext.points_bonus ||
                !visibleSeries.find(
                  e => e.dataFields.valueY == "points_bonus"
                )) &&
              (!dataItem.dataContext.points_activity ||
                !visibleSeries.find(
                  e => e.dataFields.valueY == "points_activity"
                ))
            ) {
              return 15
            }
            break
          case "points_bonus":
            if (
              !dataItem.dataContext.points_activity ||
              !visibleSeries.find(e => e.dataFields.valueY == "points_activity")
            ) {
              return 15
            }
            break
          case "points_activity":
            return 15
        }
      }
      return radius
    }

    let activityTemplate = activitySeries.columns.template
    activityTemplate.width = 15
    activityTemplate.column.adapter.add("cornerRadiusTopLeft", topRadius)
    activityTemplate.column.adapter.add("cornerRadiusTopRight", topRadius)
    activityTemplate.column.adapter.add("cornerRadiusBottomLeft", bottomRadius)
    activityTemplate.column.adapter.add("cornerRadiusBottomRight", bottomRadius)
    activityTemplate.strokeOpacity = 0
    //* Different color when past goal
    // activityTemplate.adapter.add("fill", (fill, target) => {
    //   if (target.dataItem && target.dataItem.valueY < this.goal) {
    //     return color(this.$cssVar("--primary"))
    //   }
    //   return fill
    // })

    let bonusTemplate = bonusSeries.columns.template
    bonusTemplate.width = 15
    bonusTemplate.column.adapter.add("cornerRadiusTopLeft", topRadius)
    bonusTemplate.column.adapter.add("cornerRadiusTopRight", topRadius)
    bonusTemplate.column.adapter.add("cornerRadiusBottomLeft", bottomRadius)
    bonusTemplate.column.adapter.add("cornerRadiusBottomRight", bottomRadius)
    bonusTemplate.strokeOpacity = 0

    let quizTemplate = quizSeries.columns.template
    quizTemplate.width = 15
    quizTemplate.column.adapter.add("cornerRadiusTopLeft", topRadius)
    quizTemplate.column.adapter.add("cornerRadiusTopRight", topRadius)
    quizTemplate.column.adapter.add("cornerRadiusBottomLeft", bottomRadius)
    quizTemplate.column.adapter.add("cornerRadiusBottomRight", bottomRadius)
    quizTemplate.strokeOpacity = 0

    let cursor = new XYCursor()
    cursor.behavior = "panX"
    cursor.lineY.disabled = true
    cursor.xAxis = dateAxis
    cursor.fullWidthLineX = true
    cursor.lineX.disabled = true
    chart.cursor = cursor

    // Zoom to show one week on mobile and one month on desktop
    const zoomToDates = () => {
      let daysToShow
      let today = new Date()
      today.setHours(24, 60, 59, 0)
      // If mobile
      if (chart.pixelWidth < 500) {
        daysToShow = 12 // <-- number of days to show on mobile
        let weekAgo = new Date()
        weekAgo.setDate(weekAgo.getDate() - daysToShow)
        weekAgo.setHours(0, 0, 0, 0)
        dateAxis.zoomToDates(weekAgo, today, false, true)
        valueAxis.title.disabled = true
      } else {
        daysToShow = 20 // <-- number of days to show on desktop
        let monthAgo = new Date()
        monthAgo.setDate(monthAgo.getDate() - daysToShow)
        monthAgo.setHours(0, 0, 0, 0)
        if (chart.data?.[0]?.date < monthAgo)
          dateAxis.zoomToDates(monthAgo, today, false, true)
        valueAxis.title.disabled = false
      }
      dateAxis.renderer.minGridDistance = dateAxis.pixelWidth / daysToShow
    }
    if (this.zoomDates) {
      chart.events.on("sizechanged", zoomToDates)
      chart.events.on("datavalidated", zoomToDates)
      chart.events.on("ready", zoomToDates)
    }
    chart.events.once("ready", () => {
      dateAxis.events.on("startendchanged", event => {
        this.onEnd = event.target.start <= 0
      })
      this.onEnd = chart.scrollbarX.start <= 0
    })

    let scrollbarX = new XYChartScrollbar()
    scrollbarX.disabled = true
    scrollbarX.series.push(activitySeries)
    scrollbarX.series.push(bonusSeries)
    scrollbarX.series.push(quizSeries)
    scrollbarX.startGrip.disabled = true
    scrollbarX.endGrip.disabled = true
    chart.scrollbarX = scrollbarX
    chart.scrollbarX.parent = chart.bottomAxesContainer
    let scrollAxis = chart.scrollbarX.scrollbarChart.xAxes.getIndex(0)
    // Disable labels and grid on scrollbar
    scrollAxis.renderer.labels.template.disabled = true
    scrollAxis.renderer.grid.template.disabled = true
    // Make column width in scrollbar 100%
    chart.scrollbarX.scrollbarChart.series.each(series => {
      series.filters.clear()
      series.columns.template.width = percent(100)
      series.columns.template.column.cornerRadiusTopLeft = 0
      series.columns.template.column.cornerRadiusTopRight = 0
      series.columns.template.column.cornerRadiusBottomLeft = 0
      series.columns.template.column.cornerRadiusBottomRight = 0
    })

    window.onresize = () => {
      chart?.svgContainer?.measure?.()
    }
    chart.responsive.enabled = true

    this.chart = chart

    function getMonday(d) {
      d = new Date(d)
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    }

    if (this.chart?.data[0] && this.chart.dateAxis) {
      const startDate = this.chart.data[0].date
      let lastMonday = getMonday(new Date())
      while (lastMonday > startDate) {
        lastMonday.setHours(0, 0, 0, 0)
        let event = this.chart.dateAxis.axisRanges.create()
        event.date = new Date(lastMonday)
        event.grid.strokeWidth = 2

        lastMonday.setDate(lastMonday.getDate() - 1)
        lastMonday = getMonday(lastMonday)
      }
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>
